import { ModuleWithProviders, NgModule } from '@angular/core';
import { AntDesignModule } from './components/ant-design/ant-design.module';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateTimeToDisplay } from './pipes/dateTimeToDisplay.pipe';
import { AmountToDisplay } from './pipes/amountToDisplay.pipe';
import { RemainingTimePipe } from './pipes/RemainingTime.pipe';
import { ChainNamePipe } from './pipes/chainName.pipe';

const IMPORTS = [
  FormsModule,
  ReactiveFormsModule,
  AntDesignModule,
  ToastrModule.forRoot()
];

const Declarations = [
  DateTimeToDisplay,
  AmountToDisplay,
  RemainingTimePipe,
  ChainNamePipe
];

const EXPORT = [
  FormsModule,
  ReactiveFormsModule,
  AntDesignModule,
  ToastrModule,
  DateTimeToDisplay,
  AmountToDisplay,
  RemainingTimePipe,
  ChainNamePipe
];

const PROVIDERS = [{ provide: NZ_I18N, useValue: en_US }];

const EntryComponents = [];

@NgModule({
  imports: IMPORTS,
  declarations: Declarations,
  exports: EXPORT,
  providers: PROVIDERS
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule
    };
  }
}
